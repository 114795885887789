(function($){
	Common = {
		init: function() {
			console.log("A");
			this.smoothScroll()
			this.scrollMagic()
			this.addSlick()
			this.share()
		},
		share: function(){
			$('#share a').on('click', function(e){
				e.preventDefault()
				window.open($(this).attr('href'),"social_window","width=500,height=800,resizable=yes,scrollbars=yes,toolbar=yes")
			})
		},
		addSlick: function(){
			$('.kv-image').slick({
				infinite: true,
				dots: false,
				arrows: false,
				autoplay: true,
				autoplaySpeed: 5000,
				fade: true,
				speed: 700,
				infinite: true,
				cssEase: 'ease-in-out',
			})
		},
		scrollMagic: function(){
			var controller = new ScrollMagic.Controller();
			var targets = $('.scrollmagic');
			var scenes = [];

			targets.each(function() {
				var target = this;
				var scene = new ScrollMagic.Scene({triggerElement : target, triggerHook : 0.75});
				scene.on('enter', function(e) {
					$(target).addClass('magic')
				});
				scene.on('leave', function(e) {
					// $(target).removeClass('magic')
				});
				scenes.push(scene);
			});
			controller.addScene(scenes);
		},
		smoothScroll: function() {
			$(document).on('click', 'a[href^="#"]', function(event) {
				event.preventDefault();
				var plus = 0;
				$(window).innerWidth() <= 767 ? plus = 50 : plus = 70;
				$('html, body').animate({
					scrollTop: $($.attr(this, 'href')).offset().top - plus
				}, 500);
			});
		},
		ticker: function() {
			var $setElm = $('.ticker');
			var effectSpeed = 1000;
			var switchDelay = 6000;
			var easing = 'swing';
			var cutFigure = '48'; // カットする文字数
			var afterTxt = '...'; // 文字カット後に表示するテキスト
			var $a = $('.ticker ul li a');
			$a.each(function() {
				var textLength = $(this).text().length;
				var textTrim = $(this).text().substr(0, (cutFigure))

				if (cutFigure < textLength) {
					$(this).html(textTrim + afterTxt).css({
						visibility: 'visible'
					});
				} else if (cutFigure >= textLength) {
					$(this).css({
						visibility: 'visible'
					});
				}
			});

			$setElm.each(function() {
				var effectFilter = $(this).attr('rel'); // 'fade' or 'roll' or 'slide'

				var $targetObj = $(this);
				var $targetUl = $targetObj.children('ul');
				var $targetLi = $targetObj.find('li');
				var $setList = $targetObj.find('li:first');

				var ulWidth = $targetUl.width();
				var listHeight = $targetLi.height();
				$targetObj.css({
					height: (listHeight)
				});
				$targetLi.css({
					top: '0',
					left: '0',
					position: 'absolute'
				});

				var liCont = $targetLi.length;

				if (effectFilter == 'fade') {
					$setList.css({
						display: 'block',
						opacity: '0',
						zIndex: '98'
					}).stop().animate({
						opacity: '1'
					}, effectSpeed, easing).addClass('showlist');
					if (liCont > 1) {
						setInterval(function() {
							var $activeShow = $targetObj.find('.showlist');
							$activeShow.animate({
								opacity: '0'
							}, effectSpeed, easing, function() {
								$(this).next().css({
									display: 'block',
									opacity: '0',
									zIndex: '99'
								}).animate({
									opacity: '1'
								}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({
									display: 'none',
									zIndex: '98'
								}).removeClass('showlist');
							});
						}, switchDelay);
					}
				} else if (effectFilter == 'roll') {
					$setList.css({
						top: '3em',
						display: 'block',
						opacity: '0',
						zIndex: '98'
					}).stop().animate({
						top: '0',
						opacity: '1'
					}, effectSpeed, easing).addClass('showlist');
					if (liCont > 1) {
						setInterval(function() {
							var $activeShow = $targetObj.find('.showlist');
							$activeShow.animate({
								top: '-3em',
								opacity: '0'
							}, effectSpeed, easing).next().css({
								top: '3em',
								display: 'block',
								opacity: '0',
								zIndex: '99'
							}).animate({
								top: '0',
								opacity: '1'
							}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({
								zIndex: '98'
							}).removeClass('showlist');
						}, switchDelay);
					}
				} else if (effectFilter == 'slide') {
					$setList.css({
						left: (ulWidth),
						display: 'block',
						opacity: '0',
						zIndex: '98'
					}).stop().animate({
						left: '0',
						opacity: '1'
					}, effectSpeed, easing).addClass('showlist');
					if (liCont > 1) {
						setInterval(function() {
							var $activeShow = $targetObj.find('.showlist');
							$activeShow.animate({
								left: (-(ulWidth)),
								opacity: '0'
							}, effectSpeed, easing).next().css({
								left: (ulWidth),
								display: 'block',
								opacity: '0',
								zIndex: '99'
							}).animate({
								left: '0',
								opacity: '1'
							}, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({
								zIndex: '98'
							}).removeClass('showlist');
						}, switchDelay);
					}
				}
			});
		}
	}

	$(function() {
		Common.init()
	})
})(jQuery)
